import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi, postApi } from '../../../scripts/api-services';
import { TOP_BRANDS } from '@/constants/endpoints/topbrandEndpoint';
import {
    ELASTIC_SEARCH,
    SUGGESTIVE_SEARCH,
} from '@/constants/endpoints/searchEndpoint';
import {
    BEST_SELLING_CATEGORY,
    BEST_SELLING_CATEGORY_PRODUCT,
    BEST_SELLING_PRODUCT,
    CONSCIOUS_WALL_PRODUCT,
    CRAZY_DEALS_LIST,
    FEATURED_CATEGORY,
    FEATURED_PRODUCT,
    GET_BANNER_LIST,
    INTRODUCING_NEW_PRODUCT,
    SUBSCRIBE_NOW,
} from '@/constants/endpoints/home-endpoints/homeEndpoints';
import toast from 'react-hot-toast';
import { boolean } from 'zod';
import { filterUniqueShades } from '@/components/slider/slider';

interface searchData {
    search: string;
    storeId: string;
}

export const getTopBrandsData = createAsyncThunk(
    'home/home-top-brands',
    async () => {
        try {
            const response = await getApi(TOP_BRANDS);
            // console.log("response getBrandData", response.data);
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*-------------------Suggestive search----------------*/

export const searchData = createAsyncThunk(
    'product/fetchSearchResult',
    async (search: any) => {
        try {
            const storeId = localStorage.getItem('location');
            const response = await postApi(SUGGESTIVE_SEARCH, {
                pageSize: 30,
                page: 1,
                search: search,
                storeId: storeId,
            });
            console.log('Response suggestive search', response);
            return response.data;
        } catch (error) {
            console.log('Error in product details', error);
            return Promise.reject(error);
        }
    }
);

/*-------------------Elastic search----------------*/

export const elasticSearchData = createAsyncThunk(
    'product/elastic-search',
    async (search: any) => {
        try {
            const response = await postApi(`${ELASTIC_SEARCH}/${search}`);
            return response;
        } catch (error) {
            console.log('Error in product details', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Featured Product ----------------*/

export const getFeaturedData = createAsyncThunk(
    'home/home-featured',
    async () => {
        try {
            const response = await getApi(FEATURED_CATEGORY);
            // console.log("response getBrandData", response.data);
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Best Selling Product ----------------*/

export const getBestSellingData = createAsyncThunk(
    'home/home-best-selling',
    async () => {
        try {
            const response = await getApi(BEST_SELLING_CATEGORY);
            // console.log("response getBrandData", response.data);
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Featured Product ----------------*/

interface Bestsellingproduct {
    page?: number | null;
    pageSize?: number | null;
}

export const getBestSellingProduct = createAsyncThunk(
    'home/home-best-selling-product',
    async ({ pageSize, page }: Bestsellingproduct) => {
        try {
            const response = await postApi(BEST_SELLING_PRODUCT, {
                pageSize,
                page,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Featured Product ----------------*/

interface Featuredproduct {
    page?: number | null;
    pageSize?: number | null;
}

export const getFeaturedProduct = createAsyncThunk(
    'home/home-featured-product',
    async ({ pageSize, page }: Featuredproduct) => {
        try {
            const response = await postApi(FEATURED_PRODUCT, {
                pageSize,
                page,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Best Selling Category Wise Product ----------------*/

interface BestsellingCategoryproduct {
    categoryId: string;
}

export const getBestSellingCategoryProduct = createAsyncThunk(
    'home/home-best-selling-Category',
    async ({ categoryId }: BestsellingCategoryproduct) => {
        try {
            const response = await postApi(BEST_SELLING_CATEGORY_PRODUCT, {
                categoryId,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Consious Wall Product ----------------*/

interface ConsiousWallproduct {
    page?: number | null;
    pageSize?: number | null;
}

export const getConsiousWallProduct = createAsyncThunk(
    'home/home-consious-product',
    async ({ pageSize, page }: ConsiousWallproduct) => {
        try {
            const response = await postApi(CONSCIOUS_WALL_PRODUCT, {
                pageSize,
                page,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Introducing new Product ----------------*/

interface IntroDucingproduct {
    page?: number | null;
    pageSize?: number | null;
}

export const getInroducingProduct = createAsyncThunk(
    'home/home-introducing-product',
    async ({ pageSize, page }: IntroDucingproduct) => {
        try {
            const response = await postApi(INTRODUCING_NEW_PRODUCT, {
                pageSize,
                page,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Creazy deals Product ----------------*/

export const getCrazyDealsProduct = createAsyncThunk(
    'home/home-crazyDeal-product',
    async () => {
        try {
            const response = await postApi(CRAZY_DEALS_LIST);
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Banner ----------------*/

interface BannerData {
    page?: number | null;
    pageSize?: number | null;
}

export const getBannerData = createAsyncThunk(
    'home/home-banner',
    async ({ pageSize, page }: BannerData) => {
        try {
            const response = await postApi(GET_BANNER_LIST, {
                pageSize,
                page,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

/*------------------ Subscribe now ----------------*/

interface Subscribe {
    email?: string;
}

export const SubscribeEmail = createAsyncThunk(
    'home/home-subscribe',
    async ({ email }: Subscribe) => {
        try {
            const response = await postApi(SUBSCRIBE_NOW, {
                email,
            });
            toast.success('Email subscribe successfully');
            return response.data;
        } catch (error: any) {
            console.log('Error fetch brand API', error);
            toast.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/*------------------Manipulator Function For best selling products ----------*/

export function extractAttribute(attributeName: string) {
    return (variant: any) => {
        if (variant.variantAttributes) {
            const attribute = variant.variantAttributes.find(
                (attribute: any) => attribute?.attribute === attributeName
            );
            if (attribute) {
                return {
                    ...attribute,
                    productId: variant?.productId,
                    _id: variant?.variantId,
                    productVariantId: variant?.variantId,
                    isOutOfStock: variant?.isOutOfStock,
                };
            }
        }
        return null;
    };
}

export function ManipualteBestSellingData(data: any) {
    return data?.flatMap((item: any) => {
        const variantShades = item?.data
            ?.flatMap(extractAttribute('shade'))
            .filter(Boolean);
        const variantML = item?.data
            ?.flatMap(extractAttribute('ml'))
            .filter(Boolean);
        const variantGM = item?.data
            ?.flatMap(extractAttribute('gm'))
            .filter(Boolean);
        const variantKG = item?.data
            ?.flatMap(extractAttribute('kg'))
            .filter(Boolean);

        if (item?.productType === 'variable') {
            return item?.data?.map((v: any) => ({
                id: item?._id,
                isOutOfStock: v?.isOutOfStock,
                path: v?.variantImages?.[0] || v?.images?.[0],
                productId: item?.productId,
                masterProductId: item?.productId,
                productVariantId: v.variantId,
                width: 100,
                sku: v?.sku,
                variantSku: v?.variantSku,
                height: 100,
                brand: v?.brandName,
                productName: v?.name || v?.productName,
                type: item.productType,
                discountedPrice: v?.discountedPrice,
                mrp: v.mrp,
                discount: v?.discount,
                variantIds: item?.data,
                variantId: v?.variantId,
                Shade: filterUniqueShades(variantShades)?.filter(Boolean)
                    ?.length,
                Sizes:
                    variantML?.length ||
                    variantGM?.length ||
                    variantKG?.length ||
                    null,
                variantShades: variantShades?.filter(Boolean),
                variantSize:
                    variantGM?.length > 0
                        ? variantGM
                        : variantKG?.length > 0
                          ? variantKG
                          : variantML,
            }));
        } else {
            return {
                id: item?._id,
                isOutOfStock: item?.isOutOfStock,
                path: item?.images[0],
                width: 100,
                sku: item?.sku,
                productId: item?._id,
                height: 100,
                masterProductId: item?._id,
                brand: item?.brandName,
                productName: item?.name || item?.productName,
                type: item?.type || item?.productType,
                discountedPrice: item?.discountedPrice,
                mrp: item?.mrp,
                discount: item?.discount,
                variantIds: null,
                Shade: null,
                Sizes: null,
                variantShades: null,
            };
        }
    });
}

/*------------------Manipulator Function for Featured Data ----------*/

export function ManipualteFeaturedData(data: any) {
    return data?.flatMap((item: any) => {
        if (item?.type === 'variable') {
            return item?.variantIds?.flatMap((variant: any) => ({
                id: variant?._id,
                path: variant?.featureBanner,
                productId: item?._id,
                width: 100,
                sku: item?.sku,
                height: 100,
                productName: variant?.title,
                type: item.type,
                price: variant?.productInventory?.[0]?.discountPrice,
                cutPrice: variant?.productInventory?.[0]?.mrp,
                variantIds: item?.variantIds,
            }));
        } else {
            return {
                id: item._id,
                path: item?.featureBanner,
                width: 100,
                sku: item?.sku,
                height: 100,

                productName: item.name,
                type: item.type,
                price: item?.productInventory?.[0]?.discountPrice,
                cutPrice: item?.productInventory?.[0]?.mrp,
                variantIds: item?.variantIds,
            };
        }
    });
}

export function ManipualteIntroductingData(data: any) {
    return data?.flatMap((item: any) => {
        if (item?.type === 'variable') {
            return item?.variantIds?.flatMap((variant: any) => ({
                id: variant?._id,
                path: variant?.introducingNewBanner,
                productId: item?._id,
                width: 100,
                sku: item?.sku,
                height: 100,
                productName: variant?.title,
                type: item.type,
                price: variant?.productInventory?.[0]?.discountPrice,
                cutPrice: variant?.productInventory?.[0]?.mrp,
                variantIds: item?.variantIds,
            }));
        } else {
            return {
                id: item._id,
                path: item?.introducingNewBanner,
                width: 100,
                sku: item?.sku,
                height: 100,

                productName: item.name,
                type: item.type,
                price: item?.productInventory?.[0]?.discountPrice,
                cutPrice: item?.productInventory?.[0]?.mrp,
                variantIds: item?.variantIds,
            };
        }
    });
}

export function ManipualteWishlistData(data: any) {
    return data?.map((item: any) => {
        const brandName = item?.attributes?.map((item: any) =>
            item.attribute === 'brand' ? item.value : null
        );

        if (item?.productType === 'variable') {
            return {
                id: item?._id,
                path: item?.images[0],
                productId: item?.productId,
                masterProductId: item?.productId,
                variantId: item?.productVariantId,
                productVariantId: item?.productVariantId,
                width: 100,
                sku: item?.masterSku,
                isOutOfStock: item?.quantity < 1,
                height: 100,
                brand: item?.brandName,
                productName: item?.name || item?.productNameName,
                type: item.productType || item?.type,
                discountedPrice: item?.discountedPrice,
                mrp: item?.mrp,
                variantIds: item?.variantIds || null,
            };
        } else {
            return {
                id: item?._id,
                wishlistSlide: true,
                path: item?.images[0],
                width: 100,
                sku: item?.sku,
                productId: item?._id,
                height: 100,
                masterProductId: item?._id,
                isOutOfStock: item?.quantity < 1,
                brand: item?.brandName,
                productName: item?.name || item?.productNameName,
                type: item?.productType || item?.type,
                discountedPrice: item?.discountedPrice,
                mrp: item?.mrp,
                variantIds: item?.variantIds || null,
            };
        }
    });
}
